/** @format */

.footerbefore::before {
  content: "";
  width: 80%;
  height: 100px;
  position: absolute;
  background-size: cover;
  background-image: url("../../../assets/footerafter.svg");
  bottom: 0px;
  right: 0%;
  z-index: 1;
}
.heading_1 {
  color: #193766;
}

.anim {
  margin: auto;
  display: block;
  position: relative;

  transition: all 0.4s ease;
  z-index: 1;
}

.anim:after {
  content: "";
  display: block;
  background: #fff;
  position: absolute;
  width: 100%;
  height: 2px;

  transition: all 0.4s ease;
}

/* .anim:hover:after {
  transform: translate(0, -100%);
  width: 100%;
  height: 100%;
  opacity: .0;
} */

.anim:hover {
  color: #ffffff;
}
