.swiper {
  width: 100%;
  padding: 0;
  /* height: ; */
}

.swiper-slide {
  text-align: center;
  padding: 0;
  font-size: 18px;
  background: rgba(255, 255, 255, 0);

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.card:hover .partner_name {
  bottom: 0%;
  background-color: rgba(128, 128, 128, 0.557);
}
.partner_name {
  border-bottom-left-radius: 23px;
  border-bottom-right-radius: 23px;
}
.swiper-slide img {
  display: block;
  padding: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "next";
  display: none;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "prev";
  display: none;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
  background-color: transparent;
}
.aboutslider_main {
  height: 50%;
  width: 80%;
  margin: 0;

  position: relative;
}

.aboutslider_main ::before {
  border-right: 30%;
  border-bottom: 30%;
  border: solid 3px;
  position: absolute;
}
